$(document).ready(function () {
	menuMobile();
	reviewsSlider();
	readMore();
	validation();
	popupValidation();
	popupPaymentValidation();
	showImages();
});

function reviewsSlider() {
	if ($(".reviews__slider").length > 0) {
		var moveToClickedNumber = function (swiper) {
			swiper.swipeTo(swiper.clickedSlideIndex);
		};
		var swiper = new Swiper(".reviews__slider", {
			slidesPerView: 1,
			loop: true,
			speed: 1100,
			pagination: {
				el: ".reviews__pagination",
				// dynamicBullets: true,
				clickable: true,
			},
			// autoplay: {
			//   delay: 5000,
			// },
			navigation: {
				nextEl: ".reviews__arrow-btn",
			},
			breakpoints: {
				320: {
					pagination: false,
					navigation: {
						nextEl: ".reviews__mobile-arrow-btn",
					},
				},
				666: {},
			},
		});

		let dots = $(".swiper-pagination-bullet");
		let dots_img = $(".dots-img");
		for (let i = 0; i < dots.length; i++) {
			dots[i].append(dots_img[i]);
		}
	}
}

function menuMobile() {
	if ($("#language").length > 0) {
		$("#language").selectmenu();
	}
	$(document).on("click", ".header__mobile-btn", function (e) {
		e.preventDefault();
		console.log("clicked");
		if ($(".header__menu").hasClass("left")) {
			$(".header-menu").removeClass("left");
		}
		if ($(".header__menu").hasClass("hide")) {
			$(this).find(".open-btn").fadeOut(0);
			$(this).find(".close-btn").fadeIn(0);
			$("body").addClass("fixed-mobile-menu");

			$(window).scroll(function () {
				return false;
			});
		} else {
			$(this).find(".open-btn").fadeIn(0);
			$(this).find(".close-btn").fadeOut(0);
			$("body").removeClass("fixed-mobile-menu");
			$(window).scroll(function () {
				return true;
			});
		}
		// $(".backdrop").toggleClass("backdrop_open");
		$(".header__menu").toggleClass("hide");
	});

	if (document.documentElement.clientWidth < 667) {
		$(document).on("click", ".sub", function () {
			$(".header-menu").addClass("left");
			let subMenuId = $(this).attr("data-sub");
			let idOpen = ".submenu-" + subMenuId;
			$("header").find(idOpen).addClass("right");
		});
		$(document).on("click", ".submenu__back-arrow", function () {
			$(this).parents(".submenu").removeClass("right");
			$(".header-menu").removeClass("left");
		});
	}
}

function readMore() {
	let texts = $(".reviews__text-desktop");

	$(".reviews__read-all").each(function () {
		$(this).on("click", function () {
			let id = parseInt($(this).attr("data-num"));
			$("#read-more-mobile").html(texts[++id]);
		});
	});
}

function validation() {
	$(".form__form, .main-form__form").validate({
		rules: {
			name: "required",
			phone: "required",
		},
		messages: {
			name: "Поле не может быть пустым",
			phone: "Поле не может быть пустым",
			notes: "Поле не может быть пустым",
			privacy: "Поле не может быть пустым"
		},
	});
}

function popupValidation() {
	$("#header__popup-form").validate({
		submitHandler: function() {
			$('.header__popup-wrapper').hide();
			$('.header__popup-sended').show();
		},
		rules: {
			name: "required",
			phone: "required",
		},
		messages: {
			name: "Поле не может быть пустым",
			phone: "Поле не может быть пустым",
			privacy: "Поле не может быть пустым"
		},
	});
}

function popupPaymentValidation() {
	$("#questionsPopup-form").validate({
		submitHandler: function() {
			$('.questionsPopup-wrapper').hide();
			$('.questionsPopup-card').show();
			$('.questionsPopup__line div').width('100%')
		},
		rules: {
			name: "required",
			phone: "required",
			email: {
				required: true,
				email: true
			}
		},
		messages: {
			name: "Поле не может быть пустым",
			phone: "Поле не может быть пустым",
			email: {
				required: "Поле не может быть пустым",
				email: "Введите корректный email"
			}
		},
	});
}

function showImages() {

	$(document).on('click', '.touch', function(){
		console.log('ok')
		$(this).closest('.dreams__img-item').find('div img').css('opacity', '1');
	})
}